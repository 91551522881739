
import { styled } from '@mui/material/styles';

import MuiAccordion from '@mui/material/Accordion';

import Acc from '@mui/material/Accordion';

const Accordion = styled((props) => (
    <Acc  elevation={0} square {...props} />
        ))(({ theme }) => ({

            
      
}));



export default Accordion;