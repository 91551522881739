
import './App.css';
import Home from './pages/Home';
import { CssBaseline } from '@mui/material';
import theme from './theme'
import { useTheme , createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { useState } from 'react';
const App = () => {
  {/* <Home/> */}


 

  return (

        <Home/>
  )
}

export default App;
